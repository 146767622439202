import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import React, { useEffect, useState } from 'react';
import arrowBackImage from '@app/assets/icons/arrow_back.svg';

import * as S from './DetailAssessmentPage.styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  getAssessmentByTargetIdGQL,
  getYMAssessmentsGQL,
  getYMTags,
  getYMTargetGQL,
  getYMTasksByTaskIdGQL,
  updateYMTaskGQL,
} from '@app/hocs/gql';
import { AllYMTags, AssessmentData, GetYMAssessmentsData, TaskData, YmTargetById } from '@app/interfaces/interfaces';
import { findTaskByTargetId, getExamMonthFromReserved, getFormatedAge, getMonth } from '@app/constants/YMData';
import { taskDatas } from '@app/hocs/cache';
import { notificationController } from '@app/controllers/notificationController';
import { calcGrowth } from '@app/utils/utils';

export default function DetailAssessmentPage() {
  const navigate = useNavigate();
  const { targetId, taskId } = useParams();
  const [targetData, setTargetData] = useState<{ name: string; bdate: string; groupName: string
    , reservedAt:string 
  } | null>(null);
  const taskDataVar = useReactiveVar(taskDatas);
  const [assessData, setAssessData] = useState<any>([]);
  const [taskLists, setTaskLists] = useState<TaskData[][]>([]);
  const { data: assessTags } = useQuery<AllYMTags>(getYMTags, {
    variables: { category: 'Assess' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getYMTasksByTaskId] = useLazyQuery(getYMTasksByTaskIdGQL);

  const { data: assessInfo, loading, error } = useQuery<AssessmentData>(getAssessmentByTargetIdGQL, {
    variables: { tgid: targetId, category: 'assess_1,assess_2' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  /*
  const { data: assessmentsTask, refetch } = useQuery<GetYMAssessmentsData>(getYMAssessmentsGQL, {
    variables: {
      category: 'assess_1,assess_2', // 원하는 카테고리 값을 여기에 입력하세요.
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  */
  const { data: target } = useQuery<YmTargetById>(getYMTargetGQL, {
    variables: { tgid: targetId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  // const getTaskStatus = (task: TaskData[]) {}

  function groupDataByExmonth(data: TaskData[]): TaskData[][] {
    const groupedData: { [key: number]: TaskData[] } = {};

    data.forEach((item) => {
      const exmonth = item.data.exmonth;
      if (!groupedData[exmonth]) {
        groupedData[exmonth] = [];
      }
      groupedData[exmonth].push(item);
    });

    const sortedData = Object.keys(groupedData)
      .map((key) => parseInt(key, 10))
      .sort((a, b) => b - a)
      .map((key) => groupedData[key]);

    return sortedData;
  }

  const onClickCard = (data: any, task: any) => {
    if (!data || !task) {
      return;
    }

    const updatedTaskLists = taskLists.flat();

    // if(updatedTaskLists)

    taskDatas({
      ...taskDataVar,
      tasks: taskLists.flat(),
    });

    navigate(`/assessment-dashboard/assessment/target/${targetId}/${task.id}`);
  };

  const getCategory = (cate: string) => {
    if (assessTags?.getYMTags.success && assessTags?.getYMTags?.tags.length) {
      return assessTags?.getYMTags?.tags.find((tag) => tag.code === cate) ?? { code: '', name: '' };
    }
    return { code: '', name: '' };
  };

  const getVideoTasksData = async (taskId: string) => {
    const result = await getYMTasksByTaskId({ variables: { taskid: taskId } });

    if (result.data.getYMTasksByTaskId?.success) {
      if (result.data.getYMTasksByTaskId?.tasks?.length) {
        const updatedVideoTasks = result.data.getYMTasksByTaskId?.tasks.map((item: { title: string; data: string }) => {
          const newItem = { ...item };
          newItem.data =
            newItem.data === '' ? {} : typeof newItem.data === 'string' ? JSON.parse(newItem.data) : newItem.data;
          return newItem;
        });

        return updatedVideoTasks?.length ? [...updatedVideoTasks] : [];
      }
      return [];
    } else {
      return [];
    }
  };
/*
  useEffect(() => {
    if (
      assessmentsTask &&
      assessmentsTask?.getYMAssessments?.success &&
      assessmentsTask?.getYMAssessments?.tasks.length &&
      targetId
    ) {
      const { tasks } = assessmentsTask?.getYMAssessments;

      const findTasksData = findTaskByTargetId(tasks, targetId, taskId)?.map((task) => ({
        ...task,
        data: task?.data === '' ? {} : typeof task?.data === 'string' ? JSON.parse(task.data) : task?.data,
      }));

      const filteredTaskByExmonth = groupDataByExmonth(findTasksData);

      const updatedTaskData = filteredTaskByExmonth?.map(async (item) => {
        if (!item) {
          return [];
        }

        const updatedItem = await Promise.all(
          item?.map(async (data) => {
            const videoTasks = await getVideoTasksData(data?.id);
            const newData = { ...data };
            newData.videoTasks = videoTasks;
            return newData;
          }),
        );
        return updatedItem;
      });

      Promise.all(updatedTaskData)task?.
        .then((resolvedData) => {
          setTaskLists(resolvedData);
        })
        .catch((error) => {
          console.error(error);
        });

      // setTaskLists(updatedTaskData);
    }
  }, [assessmentsTask]);
*/

  const makeAssessment = async () =>{
    const assess:any = [];
    if( assessInfo?.getYMAssessmentsByTargetId.tasks.length === 0) return;

    // @ts-ignore
    const tasks = assessInfo.getYMAssessmentsByTargetId.tasks;
    const promises:any = Object.keys(tasks).map(async function(key:any) {
      const vtasks = await getVideoTasksData(tasks[key].id);
      assess.push({task:tasks[key], data: JSON.parse(tasks[key].data), em: vtasks});
    });
    await Promise.all(promises);

    setAssessData(assess);
  }

  useEffect(() => {
    //data.getYMAssessmentsByTargetId.tasks
    if (assessInfo?.getYMAssessmentsByTargetId?.success) {
      makeAssessment();
    }
  }, [assessInfo]);

  useEffect(() => {
    if (target?.getYMTarget?.success) {
      const {
        getYMTarget: {
          target: {
            title,
            data,
            group: { title: groupName },
            reservedAt,
          },
        },
      } = target;
      setTargetData({
        name: title,
        bdate: String(getMonth(JSON.parse(data).bdate)),
        groupName,
        reservedAt,
      });
    }
  }, [target]);
  
  const exMonth = (task:any) => {
    //{task?.data.exmonth}개월
    if( task.task.category === "assess_2")
      return task?.data.exmonth<0?(-task?.data.exmonth)+"개월":task?.data.exmonth+"개월";

    if( target === undefined ) return '';
    const data = JSON.parse(target?.getYMTarget.target.data);
    //return '';
    return getFormatedAge(getExamMonthFromReserved(data.bdate, data.epweek, data.epday, Number(target?.getYMTarget.target.reservedAt)),1)
  } 
/**
                <Link to={ targetData?.groupName.includes('학부모')? "/assessment-online-dashboard/0/0":"/assessment-school-dashboard/0/0"}>
                  <img width="40" src={arrowBackImage} alt="back image" />{' '}
                </Link>

 */
  return (
    <>
      {assessInfo && (
        <>
          <PageTitle>{'채점 기록・진행'}</PageTitle>

          <S.TitleWrapper justify="space-between">
            <BaseCol xl={24} md={24}>
              <BaseFormTitle className="form-title">
                채점 기록・진행
              </BaseFormTitle>
            </BaseCol>
          </S.TitleWrapper>

          <S.TargetInfoBox>
            <span>{targetData?.name}</span><span>{targetData?.groupName}</span>
          </S.TargetInfoBox>
          
          <S.CardWrapper>
            <S.CardLists>
              {assessData?.length
                ? assessData?.map((task:any, i:any) =>
                    !task ? (
                      <></>
                    ) : ( (task?.data.exmonth > 100 || task?.data.exmonth < -100)? <></>:
                      <S.CardItem
                        key={i}
                        onClick={() => {
                          onClickCard(task?.data.exmonth, task.task);
                        }}
                      >
                        <S.StatusAndBirthDateTag status={task.task?.status !== 2 && task.task?.status !== 9 ? 0 : 1}>
                          <S.StatusDot
                            status={
                              task.task?.status !== 2 && task.task?.status !== 9
                                ? 3
                                : task.data[task?.category]?.growthResult
                            }
                          />
                          <span className="status-text">
                            {task.task?.status !== 2 && task.task?.status !== 9
                              ? '채점 필요'
                              : calcGrowth(task?.data[task.task?.category]?.growthResult)}
                          </span>
                          <span className="bd-date">{exMonth(task)}</span>
                        </S.StatusAndBirthDateTag>
                        <div style={{ display: task.task?.status !== 2 ? 'inherit' : 'flex' }}>
                          {task.em.map((item:any, sIndex:any) => {
                            return task.task?.status !== 2 && task.task?.status !== 9 ? (
                              <S.BottomWrapper key={sIndex}>
                                  <S.TaskCheckbox key={i} checked={!!item?.data?.video}>
                                    <S.TaskLabel>{item?.title === '' ? '검사' : item?.title}</S.TaskLabel>
                                  </S.TaskCheckbox>
                              </S.BottomWrapper>
                            ) : (
                              item.data && item.data[item.category]?.statusTag?.length && (
                                <span key={sIndex}>
                                  <S.TagWrapper>
                                    {item.data[item.category]?.statusTag?.map((tag: string, i: number) => (
                                      <S.ResultTag key={i}>{tag}</S.ResultTag>
                                    ))}
                                  </S.TagWrapper>
                                </span>
                              )
                            );
                          })}
                        </div>
                      </S.CardItem>
                    ),
                  )
                : null}
            </S.CardLists>
          </S.CardWrapper>
        </>
      )}
      {/*</S.SectionWrapper>*/}
    </>
  );
}
